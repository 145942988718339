import React, { useMemo } from 'react';
import { Box, Card, Typography, CardContent } from '@mui/material';
import config from '@configFile';
import { DealPostType } from '@types';
import ImageSegment from './components/ImageSegment';
import { sanitizeParagraphs, processImageInformation } from './utils';

const DealEditorsNotes = ({
  deal,
  tag = config.AFFILIATE_TAGS.JD_IMAGES
}: {
  deal: DealPostType;
  tag?: string;
}) => {
  const paragraphs = useMemo(
    () => sanitizeParagraphs(deal.postText),
    [deal.postText]
  );
  const imageSegments = useMemo(
    () => processImageInformation(deal?.highResImagesInformation),
    [deal?.highResImagesInformation]
  );

  if (!deal.postText) {
    return null;
  }

  return (
    <>
      {deal.postText ? (
        <Card
          sx={{
            border: 'none',
            boxShadow: 'none',
            paddingBottom: '0px !important'
          }}
          variant="outlined"
        >
          <CardContent
            sx={{
              color: '#000',
              backgroundColor: 'white',
              padding: '0',
              paddingBottom: '0px !important'
            }}
          >
            {paragraphs.map((paragraph, index) => {
              const isLastBlock =
                index === paragraphs.length - 1 && paragraphs.length > 1;
              return (
                <React.Fragment key={paragraph}>
                  <Typography
                    key={paragraph}
                    variant="body1"
                    paragraph
                    sx={{
                      marginTop: '16px',
                      marginBottom: '0px',
                      whiteSpace: 'pre-wrap'
                    }}
                  >
                    {paragraph.trim()}
                  </Typography>
                  <ImageSegment
                    index={index}
                    imageSegments={imageSegments}
                    deal={deal}
                    tag={tag}
                    ASIN={deal.ASIN}
                    isLastBlock={isLastBlock}
                  />
                </React.Fragment>
              );
            })}
            {deal.submittedBy && !deal.s ? (
              <Box marginTop="12px" marginBottom="0px">
                <Typography
                  variant="body1"
                  sx={{
                    fontStyle: 'italic'
                  }}
                >
                  (Thank you {deal.submittedBy}!)
                </Typography>
              </Box>
            ) : null}
          </CardContent>
        </Card>
      ) : null}
    </>
  );
};

export default DealEditorsNotes;
