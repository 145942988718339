import React, { memo } from 'react';
import _ from 'lodash';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import config from '@configFile';
import { DealPostType } from '@types';
import MonetizingLink from '@components/MonetizingLink';
import { getProductLink, parseTitle, getPostPrice } from '../../../../utils';
import dealPostOptions from '../../../../utils/dealPostOptions';

interface DealPostFeedCardProps {
  deal: DealPostType;
  tag?: string;
}

const standardStyle = {
  marginTop: '20px',
  marginBottom: '0px',
  whiteSpace: 'pre-wrap',
  fontSize: '14px',
  '@media (max-width: 540px)': {
    fontSize: '14px'
  }
};

const linkStyle = {
  ...standardStyle,
  color: '#9a5691',
  // hover
  '&:hover': {
    color: '#c9cf6a',
    transition: 'color .5s'
  }
};

const DealPostSummary = ({
  deal,
  tag = config.AFFILIATE_TAGS.FEED
}: DealPostFeedCardProps) => {
  const { title, isExpired } = deal;

  const priceDisplayStyle = {
    ...standardStyle,
    fontWeight: 700
  };

  const randomDealPostOption =
    dealPostOptions[Math.floor(Math.random() * dealPostOptions.length)];

  const renderPostPrice = () => {
    if (isExpired) {
      return null;
    }
    return (
      <Typography sx={priceDisplayStyle} display="inline">
        {getPostPrice(deal, true)}
      </Typography>
    );
  };

  return (
    <Box marginTop="8px">
      <Typography variant="body1" sx={standardStyle} component="span">
        {isExpired
          ? "This deal is unavailable now but here's the information we have for Amazon's"
          : randomDealPostOption}

        <Typography
          display="inline"
          component="span"
          margin="0 4px"
          variant="body1"
        >
          <MonetizingLink
            href={getProductLink(deal.ASIN, tag, deal?.sLink || '')}
            target="_blank"
            underline="hover"
            sx={{
              ...linkStyle,
              fontWeight: 700
            }}
            item="deal-post-feed-card-summary"
            clickType={getProductLink(deal.ASIN, tag, deal?.sLink || '')}
            tag={tag}
          >
            {_.trimEnd(parseTitle(title), isExpired ? '.' : ``)}
            {isExpired ? '.' : ``}
          </MonetizingLink>
        </Typography>
        {isExpired ? '' : `for`}
        {renderPostPrice()}
      </Typography>
    </Box>
  );
};

export default memo(DealPostSummary);
