import SellIcon from '@mui/icons-material/Sell';
import CampaignIcon from '@mui/icons-material/Campaign';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import ShoppingCartCheckoutIcon from '@mui/icons-material/ShoppingCartCheckout';
import DifferenceIcon from '@mui/icons-material/Difference';
import InsightsIcon from '@mui/icons-material/Insights';
import { styled } from '@mui/system';
import {
  ringingAnimation,
  lightningAnimation,
  bounceAnimation,
  wipeInAnimation
} from '@utils/theme';

export const SubscribeSaveIcon = styled(AutorenewIcon)({
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  },
  animation: 'rotate 3s linear infinite'
});

export const CouponIcon = styled(SellIcon)({
  ...ringingAnimation,
  animation: 'ring 3s .7s ease-in-out infinite'
});

export const PromotionIcon = styled(CampaignIcon)({
  '@keyframes rotate': {
    '0%': {
      transform: 'rotate(0deg)'
    },
    '100%': {
      transform: 'rotate(360deg)'
    }
  },
  '@keyframes pulse': {
    '0%': {
      transform: 'scale(1)',
      opacity: 1
    },
    '50%': {
      transform: 'scale(1.2)',
      opacity: 0.7
    },
    '100%': {
      transform: 'scale(1)',
      opacity: 1
    }
  },
  animation: 'pulse 3s ease-in-out infinite'
});

export const TrendingIcon = styled(InsightsIcon)({
  ...lightningAnimation,
  animation: 'lightning 3s linear infinite'
});

export const SaveAtCheckoutIcon = styled(ShoppingCartCheckoutIcon)({
  '@keyframes fadeSlideIn': {
    from: {
      opacity: 0,
      transform: 'translateY(20px)'
    },
    to: {
      opacity: 1,
      transform: 'translateY(0)'
    }
  },
  animation: 'fadeSlideIn 3s ease-out infinite'
});

export const LowestIcon = styled(TrendingDownIcon)({
  ...wipeInAnimation,
  animation: 'wipeIn 3s ease-out infinite'
});

export const VariationIcon = styled(DifferenceIcon)({
  ...bounceAnimation,
  animation: 'bounce 3s infinite'
});
