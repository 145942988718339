import React, { useState, useEffect } from 'react';
import SellIcon from '@mui/icons-material/Sell';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Theme,
  Typography
} from '@mui/material';

import { makeStyles } from '@mui/styles';
import config from '@configFile';
import { useGetLatestDeals } from '@hooks/useDeals';
import trackUse from '@utils/trackUse';
import { logPostHogEvent } from '@utils/index';
import CouponLists from '../../pages/Coupons/components/CouponLists';
import colors from '../../utils/colors';
import { iconButtonHoverRaiseStyle } from '../../utils/styles';

const useStyles = makeStyles((theme: Theme) => ({
  badgeIcon: {
    bottom: '12px!important',
    right: '4px!important'
  },
  couponListContainer: {
    padding: '16px',
    marginTop: '10px',
    backgroundColor: '#80808029',
    '@media (max-width: 540px)': {
      padding: '6px'
    }
  },
  couponText: {
    margin: '4px 16px 4px 16px'
  },
  couponButtonDialogContent: { padding: '10px 0' },
  sellIcon: { color: colors.coupon },
  iconButton: { ...iconButtonHoverRaiseStyle },
  dialogContainer: {
    padding: '0 0 10px 0'
  },
  closeIcon: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: theme.palette.grey[500]
  }
}));

const CouponButton: React.FC<{
  forceOpen?: boolean;
  showIcon?: boolean;
  onClose?: () => void;
  ASIN: string;
  promoCode: string;
  tag?: string;
  size?: 'small' | 'medium' | 'large';
}> = ({
  ASIN,
  promoCode,
  size = 'small',
  tag = config.AFFILIATE_TAGS.COUPON_PAGE,
  onClose,
  showIcon = true,
  forceOpen = false
}) => {
  const { data: dealsData } = useGetLatestDeals();
  const latestDeals = dealsData?.latestDeals;
  const [open, setOpen] = useState(forceOpen || false);
  const howManyDealsHaveThisPromoCode = (latestDeals || [])?.filter(
    (deal) => deal.promoCode === promoCode
  ).length;

  const classes = useStyles();

  useEffect(() => {
    if (forceOpen) {
      setOpen(true);
    }
  }, [forceOpen]);

  const handleClick = () => () => {
    trackUse({
      item: 'show-coupons',
      value: ASIN,
      type: 'ACTION'
    });
    logPostHogEvent('handle-click-coupon-iconbutton', {
      value: ASIN,
      type: 'ACTION'
    });

    setOpen((prev) => !prev);
  };

  const handleClose = () => {
    if (onClose) {
      onClose();
    }
    setOpen(false);
  };

  return (
    <Box
      sx={{
        marginRight: howManyDealsHaveThisPromoCode < 2 ? '0px' : '8px'
      }}
    >
      {showIcon ? (
        <Tooltip title="See coupon information">
          <Badge
            badgeContent={howManyDealsHaveThisPromoCode}
            invisible={open || howManyDealsHaveThisPromoCode < 2}
            color="primary"
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right'
            }}
            classes={{
              badge: classes.badgeIcon
            }}
          >
            <IconButton
              key="btn-fav-no-user"
              size={size}
              onClick={handleClick()}
              aria-label="Show coupon information"
              className={classes.iconButton}
            >
              <SellIcon
                data-testid="coupon-button"
                className={classes.sellIcon}
              />
            </IconButton>
          </Badge>
        </Tooltip>
      ) : null}
      <Dialog
        open={open}
        onClose={() => {
          setOpen(false);
        }}
        className={classes.dialogContainer}
        maxWidth="md"
        fullWidth
        PaperProps={{
          sx: {
            margin: '8px',
            width: '100%'
          }
        }}
      >
        <DialogTitle>Coupon details</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          className={classes.closeIcon}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent className={classes.couponButtonDialogContent}>
          <Typography variant="body1" className={classes.couponText}>
            Click the coupon link to see all Amazon items this one time coupon
            will work on.
          </Typography>
          <Box className={classes.couponListContainer}>
            <CouponLists
              singleCode={promoCode}
              gridItemProps={{
                xs: 12
              }}
              openExpanded
              tag={tag}
              noCard
              showNoCouponFoundMessage
            />
          </Box>
          <DialogActions>
            <Button
              onClick={() => {
                setOpen(false);
              }}
            >
              Close
            </Button>
          </DialogActions>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CouponButton;
