/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo } from 'react';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useHistory } from 'react-router';
import config from '@configFile';
import { useGetTopDeals } from '@hooks/useTopDeals';
import { DealPostType } from '@types';
import {
  trackLinkClick,
  formatPrice,
  getProductLink,
  getCouponLink
} from '@utils/index';
import MonetizingLink from '@components/MonetizingLink';
import colors from '@utils/colors';
import IsCouponUsed from '@components/IsCouponUsed';
import {
  SubscribeSaveIcon,
  VariationIcon,
  LowestIcon,
  SaveAtCheckoutIcon,
  CouponIcon,
  PromotionIcon,
  TrendingIcon
} from './components/Icons';
import useStyles from './styles';
import {
  listItemStyle,
  primaryStyles,
  secondaryStyles,
  listItemAvatarStyle
} from './utils';

export default function DealFacts({
  deal,
  tag = config.AFFILIATE_TAGS.PRODUCT_PAGE,
  couponMessageInitialOpen = false
}: {
  deal: DealPostType;
  tag?: string;
  couponMessageInitialOpen?: boolean;
}) {
  const { data: topDealsData } = useGetTopDeals();
  const history = useHistory();
  const classes = useStyles();

  const isTopDeal = useMemo(
    () => topDealsData?.some((topDeal) => topDeal.ASIN === deal.ASIN),
    [topDealsData, deal.ASIN]
  );

  const showAverage = deal.averagePrice && deal.finalPrice < deal.averagePrice;
  const showCoupon = deal.couponFixed || deal.couponPercentage;
  const showExtraDealLink = deal.extraDeal && deal.extraDealLink;
  const showVariations = deal.parentASIN;
  const showTopDeal = isTopDeal;
  const hasDiscountAtCheckout = deal.promoFixed && deal.promoFixed > 0;

  if (
    !showAverage &&
    !showCoupon &&
    !showExtraDealLink &&
    !showVariations &&
    !showTopDeal &&
    !hasDiscountAtCheckout
  ) {
    return null;
  }

  const renderListItem = (
    icon: React.ReactNode,
    iconColor: string,
    primary: React.ReactNode,
    secondary: React.ReactNode
  ) => {
    return (
      <ListItem sx={listItemStyle}>
        <ListItemAvatar sx={listItemAvatarStyle}>
          <Avatar
            sx={{
              background: iconColor
            }}
          >
            {icon}
          </Avatar>
        </ListItemAvatar>
        <ListItemText primary={primary} secondary={secondary} />
      </ListItem>
    );
  };

  return (
    <>
      {deal?.promoCode ? (
        <Box marginTop="12px">
          <IsCouponUsed
            ASIN={deal.ASIN}
            tag={tag}
            promoCode={deal.promoCode}
            couponMessageInitialOpen={couponMessageInitialOpen}
          />
        </Box>
      ) : null}
      <List className={classes.dealFactsList}>
        {isTopDeal
          ? renderListItem(
              <TrendingIcon />,
              colors.trending,
              <Typography variant="body1" sx={primaryStyles}>
                Trending
              </Typography>,
              <Typography variant="body1" sx={secondaryStyles}>
                This has been one of the most popular deals among our community
                this week.{' '}
                <Link
                  href="/p/best-selling-deals"
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                    trackLinkClick(e, 'see-all-best-selling-deals')
                  }
                  sx={{
                    display: 'block'
                  }}
                >
                  {`See all of the best selling deals this week >>>`}
                </Link>
              </Typography>
            )
          : null}
        {showAverage
          ? renderListItem(
              <LowestIcon />,

              colors.belowAverage,
              <Typography variant="body1" sx={primaryStyles}>
                Lower than average price
              </Typography>,
              <Typography variant="body1" sx={secondaryStyles}>
                The current price of{' '}
                <strong>{formatPrice(deal.finalPrice)}</strong> is lower than
                the average purchased price of{' '}
                <strong>{formatPrice(deal.averagePrice)}</strong>.
              </Typography>
            )
          : null}
        {hasDiscountAtCheckout
          ? renderListItem(
              <SaveAtCheckoutIcon />,
              colors.promotion,
              <Typography variant="body1" sx={primaryStyles}>
                Automatic discount at checkout
              </Typography>,
              <Typography variant="body1" sx={secondaryStyles}>
                Check below the product price on the Amazon page for the words{' '}
                <strong>{`"Save ${formatPrice(
                  deal.promoFixed
                )} at checkout".`}</strong>
              </Typography>
            )
          : null}
        {showCoupon
          ? renderListItem(
              <CouponIcon />,
              colors.coupon,
              <Typography variant="body1" sx={primaryStyles}>
                Coupon available
              </Typography>,
              <Typography variant="body1" sx={secondaryStyles}>
                {getCouponLink(deal, tag) ? (
                  <MonetizingLink
                    href={getCouponLink(deal, tag)}
                    onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                      trackLinkClick(e, 'deal-coupon-available')
                    }
                    target="_blank"
                    sx={{
                      display: 'block'
                    }}
                    clickType="deal-coupon-available"
                    tag={tag}
                    underline="always"
                  >
                    {`See all items eligible for the one time ${
                      deal.couponFixed
                        ? `${formatPrice(deal.couponFixed)} off`
                        : `${deal.couponPercentage}% off`
                    } coupon >>>`}
                  </MonetizingLink>
                ) : (
                  <MonetizingLink
                    href={getProductLink(deal.ASIN, tag)}
                    clickType="deal-coupon-available"
                    tag={tag}
                    target="_blank"
                    underline="always"
                  >
                    {`Click to clip and instantly save an extra ${
                      deal.couponFixed
                        ? `${formatPrice(deal.couponFixed)} off`
                        : `${deal.couponPercentage}% off`
                    } the price at Amazon.`}
                  </MonetizingLink>
                )}
              </Typography>
            )
          : null}

        {showExtraDealLink
          ? renderListItem(
              <PromotionIcon />,
              colors.extraDeal,
              <Typography variant="body1" sx={primaryStyles}>
                Promotion available
              </Typography>,
              <Typography variant="body1" sx={secondaryStyles}>
                <MonetizingLink
                  href={`${deal.extraDealLink}?tag=${tag}`}
                  target="_blank"
                  clickType="deal-summary-promotion-link"
                  tag={tag}
                  underline="always"
                >
                  {`${deal.extraDeal} on Amazon.com >>>`}
                </MonetizingLink>
              </Typography>
            )
          : null}
        {showVariations
          ? renderListItem(
              <VariationIcon />,
              colors.variation,
              <Typography variant="body1" sx={primaryStyles}>
                Variations available
              </Typography>,
              <Typography variant="body1" sx={secondaryStyles}>
                <Link
                  href={`/variations?ASIN=${deal.parentASIN}`}
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
                    trackLinkClick(e, 'see-all-variations')
                  }
                >
                  {`See all variations >>>`}
                </Link>
              </Typography>
            )
          : null}
        {deal.ss && deal.maxSs
          ? renderListItem(
              <SubscribeSaveIcon />,
              colors.subscribeSave,
              <Typography variant="body1" sx={primaryStyles}>
                Subscribe & Save discount available
              </Typography>,
              <Typography variant="body1" sx={secondaryStyles}>
                Use Subscribe & Save to save up to an <b>extra {deal.maxSs}%</b>{' '}
                off on the price.
                <Link
                  onClick={(e: React.MouseEvent<HTMLAnchorElement>) => {
                    trackLinkClick(e, 'see-all-subscribe-save');
                    history.push('/best-amazon-subscribe-save-deals');
                    // wait a quarter second then scroll to the top
                    setTimeout(() => {
                      window.scrollTo({
                        top: 0,
                        behavior: 'smooth'
                      });
                    }, 250);
                  }}
                  sx={{
                    display: 'block',
                    cursor: 'pointer'
                  }}
                >
                  {`See all Subscribe & Save deals >>>`}
                </Link>
              </Typography>
            )
          : null}
      </List>
    </>
  );
}
