import React from 'react';
import { Link, Tooltip, Typography } from '@mui/material';
import { formatDistance } from 'date-fns';
import config from '@configFile';
import { formatDateTime, trackLinkClick } from '../../utils';

const PosterDetails = ({ postDate }: { postDate?: string | null }) => {
  if (!postDate) {
    return (
      <Typography variant="caption">
        {`Posted by `}
        <Link
          onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
            trackLinkClick(e, `poster-details-${config.admin.firstName}`)
          }
          target="_blank"
          href={config.admin.socialLink}
          sx={{
            fontSize: '0.75rem'
          }}
        >
          {`${config.admin.firstName} | ${config.admin.title}`}
        </Link>
      </Typography>
    );
  }

  return (
    <>
      <Tooltip title={`${formatDateTime(new Date(postDate))}`}>
        <span>
          {`Posted ${formatDistance(new Date(postDate), new Date(), {
            addSuffix: true
          })} `}
        </span>
      </Tooltip>
      <Link
        onClick={(e: React.MouseEvent<HTMLAnchorElement>) =>
          trackLinkClick(e, `poster-details-${config.admin.firstName}`)
        }
        target="_blank"
        href={config.admin.socialLink}
        sx={{
          fontSize: '0.75rem'
        }}
      >
        {`by ${config.admin.firstName} | ${config.admin.title}`}
      </Link>
    </>
  );
};

export default PosterDetails;
