import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  dealFactsList: {
    width: '100%',
    bgcolor: 'background.paper',
    marginTop: '12px',
    paddingBottom: '0px',
    paddingTop: '0px'
  }
}));
