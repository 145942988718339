import chalk from 'chalk';
import { DealPostType } from '@types';
import extraDealMap from './extraDealMapper/map';

function extraDealHasCoupon(
  deal: Pick<DealPostType, 'couponFixed' | 'couponPercentage'>
): boolean {
  return !!(deal.couponFixed || deal.couponPercentage);
}

interface DealConfigType {
  getX?: number; // The quantity of items to get
  forPriceOfY?: number; // The price for the "getX" items
  x?: number; // The base quantity of items for another type of deal
  saveY?: number; // The amount saved in another type of deal
  percentOffOne?: number; // Percentage off on one item in yet another deal type
  maximumFinalPrice?: number; // The maximum final price for the deal
}

interface ThisType
  extends Pick<
    DealPostType,
    | 'extraDeal'
    | 'finalPrice'
    | 'category'
    | 'couponFixed'
    | 'couponPercentage'
    | 'qtyTerm'
    | 'qty'
    | 'extraDealLink'
    | 'currentPrice'
    | 'qtyLimit'
    | 'priceAfterCoupon'
    | 'maxSs'
    | 'ss'
  > {}

export const getExtraDealPrice = (
  deal: ThisType
): Record<string, any> | null => {
  let returnValue: Record<string, any> | null = null;

  // Check if the deal is disqualified based on certain criteria
  if (
    !deal.extraDeal ||
    !deal.finalPrice ||
    deal.category === 'Books' ||
    extraDealHasCoupon(deal)
  ) {
    console.log(
      chalk.blue(
        `Returning null early due to: ${
          !deal.extraDeal
            ? 'extra deal is missing'
            : !deal.finalPrice
            ? 'final price is missing'
            : extraDealHasCoupon(deal)
            ? 'extra deal has coupon'
            : 'category is books'
        }`
      )
    );
  } else {
    // Normalize the extraDeal string to look up in the map.
    const normalizedDeal = deal.extraDeal
      .toLowerCase()
      .trim()
      .replace(/!|\./g, '')
      .replace('savings ', '');

    // Process deal if it exists in the map.
    if (extraDealMap[normalizedDeal]) {
      const dealConfig = extraDealMap[normalizedDeal];
      returnValue = dealHasValidConfig(deal, dealConfig);
      if (returnValue && deal.extraDealLink) {
        returnValue.extraDealLink = deal.extraDealLink;
      }
    } else {
      console.log(
        `Returning null because "${normalizedDeal}" is not in the map`
      );
    }
  }

  return returnValue;
};

const roundToNearestTwoDecimals = (num: number): number => {
  return Math.round(num * 100) / 100;
};

const getMultiDiscountConfig = (
  deal: ThisType,
  dealConfig: DealConfigType
): Record<string, any> | null => {
  const qty = deal.qtyTerm && deal.qty ? deal.qty : 1;
  const multibuyDiscount = roundToNearestTwoDecimals(
    deal.currentPrice * (dealConfig.getX - dealConfig.forPriceOfY)
  );
  // subscribe & save discount for y
  const ssDiscount = roundToNearestTwoDecimals(
    deal.currentPrice * dealConfig.getX * ((deal.maxSs || 0) / 100)
  );
  const total = roundToNearestTwoDecimals(deal.currentPrice * dealConfig.getX);
  const extraDealPrice = total - multibuyDiscount - ssDiscount;

  return {
    extraDeal: deal.extraDeal,
    extraDealPrice,
    eachPrice: extraDealPrice / dealConfig.getX,
    eachTermPrice: extraDealPrice / qty,
    multibuyDiscount,
    ...dealConfig
  };
};

const getBuyManyConfig = (
  deal: ThisType,
  dealConfig: DealConfigType
): Record<string, any> | null => {
  const qty = deal.qtyTerm && deal.qty ? deal.qty : 1;
  if (deal.finalPrice > dealConfig.maximumFinalPrice) {
    return null;
  }

  const { x } = dealConfig;
  // calculate the total price for the deal

  const multibuyDiscount = roundToNearestTwoDecimals(dealConfig.saveY);
  // subscribe & save discount for y
  const ssDiscount = roundToNearestTwoDecimals(
    deal.currentPrice * dealConfig.x * ((deal.maxSs || 0) / 100)
  );
  const total = roundToNearestTwoDecimals(deal.currentPrice * dealConfig.x);
  const extraDealPrice = total - multibuyDiscount - ssDiscount;

  return {
    extraDeal: deal.extraDeal,
    extraDealPrice,
    eachPrice: extraDealPrice / x,
    eachTermPrice: extraDealPrice / (qty * x),
    multibuyDiscount,
    ...dealConfig
  };
};

const getDiscountOffOneConfig = (
  deal: ThisType,
  dealConfig: DealConfigType
): Record<string, any> | null => {
  const qty = deal.qtyTerm && deal.qty ? deal.qty : 1;
  const { x } = dealConfig;
  const totalBefore = deal.currentPrice * dealConfig.x;
  const percentOverallDiscount = dealConfig.percentOffOne / x;
  const maxSSDiscount = roundToNearestTwoDecimals(
    deal.currentPrice * x * ((deal.maxSs || 0) / 100)
  );
  const multibuyDiscount = roundToNearestTwoDecimals(
    deal.currentPrice * x * (percentOverallDiscount / 100)
  );
  const extraDealPrice = totalBefore - multibuyDiscount - maxSSDiscount;

  return {
    extraDeal: deal.extraDeal,
    extraDealPrice,
    eachPrice: extraDealPrice / x,
    eachTermPrice: extraDealPrice / (qty * x),
    multibuyDiscount,
    ...dealConfig
  };
};

const dealHasValidConfig = (
  deal: ThisType,
  dealConfig: DealConfigType
): Record<string, any> | null => {
  if (dealConfig.getX && dealConfig.forPriceOfY) {
    return getMultiDiscountConfig(deal, dealConfig);
  }

  if (
    dealConfig.x &&
    dealConfig.saveY &&
    deal.finalPrice &&
    dealConfig.maximumFinalPrice
  ) {
    return getBuyManyConfig(deal, dealConfig);
  }

  if (dealConfig.x && dealConfig.percentOffOne) {
    return getDiscountOffOneConfig(deal, dealConfig);
  }

  return null;
};

interface PriceOptionsType {
  ssOverride?: number;
}

export const getPriceLineCalculations = (
  dealData: DealPostType,
  options: PriceOptionsType = {}
) => {
  const {
    ss,
    currentPrice,
    couponPercentage,
    maxSs: maxSsOriginal,
    couponFixed,
    promoFixed
  } = dealData;
  const maxSs =
    typeof options?.ssOverride === 'number'
      ? options?.ssOverride
      : maxSsOriginal;
  const itemsTotal = (currentPrice || 0).toFixed(2);
  let couponDiscount = 0;
  let ssDiscount = 0;
  const hasCoupon = !!(couponPercentage || couponFixed);
  if (ss) {
    ssDiscount = (currentPrice || 0) * ((100 - (100 - maxSs)) / 100);
  }

  if (couponFixed) {
    couponDiscount = couponFixed;
  } else if (couponPercentage) {
    couponDiscount = (couponPercentage / 100) * (currentPrice || 0);
  }

  const couponSavingsString = couponFixed
    ? 'Your Amazon Coupon Savings:'
    : `Your Amazon Coupon Savings (${couponPercentage}%):`;

  return {
    itemsTotal,
    couponDiscount,
    hasCoupon,
    ssDiscount,
    promoFixed,
    couponSavingsString,
    finalPrice:
      (currentPrice || 0) - couponDiscount - ssDiscount - (promoFixed || 0)
  };
};
