import React, { useState } from 'react';
import { Box, Typography } from '@mui/material';
import config from '@configFile';
import { DealPostType } from '@types';
import {
  formatPrice,
  getCouponLink,
  getProductLink,
  roundTo,
  getPriceLineCalculations,
  addAffiliateTagToLink
} from '@utils/index';
import MonetizingLink from '@components/MonetizingLink';
import Line from './components/Line';
import SeePriceAmazon from './components/SeePriceAmazon';
import SubscribeAndSaveChooser from './components/SubscribeAndSaveChooser';
import ExtraDealReceipt from './components/ExtraDealReceipt';
import useStyles from './styles';

export type LineConfigType = Array<{
  left: React.ReactNode;
  right: React.ReactNode;
  isTotal?: boolean;
  key: string;
}>;

interface Props {
  receiptDeal: DealPostType;
  tag?: string;
}

export default ({
  receiptDeal: dealData,
  tag = config.AFFILIATE_TAGS.HOW_TO_GET_DEAL
}: Props) => {
  const [selectedCalculationSS, setSelectedCalculationSS] = useState(
    dealData?.maxSs || 0
  );
  const { promoFixed, ss, maxSs } = dealData;
  const classes = useStyles();

  const productURL = getCouponLink(dealData, tag)
    ? getCouponLink(dealData, tag)
    : getProductLink(dealData.ASIN, tag, dealData?.sLink || '');

  const options: {
    ssOverride?: number;
  } = {};

  if (ss) {
    options.ssOverride = selectedCalculationSS;
  }

  const pricing = getPriceLineCalculations(dealData, options);

  // If final price is 0 or null or undefined, don't show receipt
  // Instead show link to see the price on Amazon. This will show
  // for expired deals.
  if (
    !pricing.finalPrice ||
    pricing.finalPrice === 0 ||
    pricing.finalPrice < 0
  ) {
    return <SeePriceAmazon ASIN={dealData.ASIN} tag={tag} />;
  }

  if (!pricing.hasCoupon && !promoFixed && !ss) {
    // if no subscribe & save, coupons, or promo, don't show receipt
    // since there isn't anything worth showing
    return null;
  }

  const lineConfig: LineConfigType = [];

  lineConfig.push({
    key: 'items',
    left: 'Items:',
    right: `$${pricing.itemsTotal}`
  });

  lineConfig.push({
    key: 'shipping',
    left: 'Shipping & handling:',
    right: '$0.00'
  });

  if (pricing.promoFixed) {
    lineConfig.push({
      key: 'promo',
      left: 'Extra Savings:',
      right: `-$${pricing.promoFixed.toFixed(2)}`
    });
  }

  if (ss && pricing.ssDiscount) {
    lineConfig.push({
      key: 'ss',
      left: `Subscribe & Save (${selectedCalculationSS}%):`,
      right: `-$${pricing.ssDiscount.toFixed(2)}`
    });
  }

  if (pricing.hasCoupon) {
    lineConfig.push({
      key: 'coupon',

      left: (
        <MonetizingLink
          tag={config.AFFILIATE_TAGS.HOW_TO_GET_DEAL}
          clickType="receipt-coupon-link"
          href={addAffiliateTagToLink(
            productURL,
            config.AFFILIATE_TAGS.HOW_TO_GET_DEAL
          )}
          style={{
            fontSize: '12px',
            lineHeight: '16px',
            color: '#0000EE',
            textDecoration: 'underline'
          }}
          sx={{
            '@media (max-width: 540px)': {
              fontSize: '12px !important',
              lineHeight: '16px !important'
            }
          }}
        >
          {pricing.couponSavingsString}
        </MonetizingLink>
      ),
      right: `-$${roundTo(pricing.couponDiscount, 2)}`
    });
  }

  let eachPriceStr = '';

  if (
    dealData.qty &&
    dealData.qtyTerm &&
    dealData.qty > 1 &&
    dealData.qtyTerm !== 'each'
  ) {
    eachPriceStr = ` (${formatPrice(pricing.finalPrice / dealData.qty)}/${
      dealData.qtyTerm
    })`;
  }

  lineConfig.push({
    key: 'total',
    left: `Order total${eachPriceStr}:`,
    right: formatPrice(pricing.finalPrice),
    isTotal: true
  });

  return (
    <>
      <Box
        className={
          ss && pricing.ssDiscount ? classes.receipt : classes.receiptPlain
        }
      >
        <Box className={classes.receiptHeader}>
          {ss && pricing.ssDiscount ? (
            <Box className={classes.subscriptionSummary}>
              Subscription summary
            </Box>
          ) : (
            <Box className={classes.orderSummary}>Order Summary</Box>
          )}
          {ss && maxSs ? (
            <Box className={classes.subscribeAndSaveChooserContainer}>
              <Typography variant="caption" className={classes.changeText}>
                Change S&S:
              </Typography>
              <SubscribeAndSaveChooser
                selected={selectedCalculationSS}
                dealData={dealData}
                onChange={(value) => setSelectedCalculationSS(value)}
              />
            </Box>
          ) : null}
        </Box>
        {lineConfig.map((line) => (
          <Line {...line} />
        ))}
      </Box>

      <ExtraDealReceipt receiptDeal={dealData} />
    </>
  );
};
