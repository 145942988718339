import React from 'react';
import { Box } from '@mui/material';
import useStyles from './styles';

const Line = ({
  left,
  right,
  isTotal
}: {
  left: React.ReactNode;
  right: React.ReactNode;
  isTotal?: boolean;
}) => {
  const classes = useStyles();
  return (
    <>
      <Box className={isTotal ? classes.lineTotal : null}>
        <span className={classes.left}>{left}</span>
        <span className={classes.right}>{right}</span>
      </Box>
    </>
  );
};

export default Line;
