export const listItemStyle = {
  padding: '0px'
};

export const primaryStyles = {
  fontWeight: 'bold',
  '@media (max-width: 540px)': {
    fontSize: '14px'
  }
};

export const secondaryStyles = {
  '@media (max-width: 540px)': {
    fontSize: '14px'
  }
};

export const listItemAvatarStyle = {
  '@media (max-width: 540px)': {
    minWidth: '50px'
  }
};
