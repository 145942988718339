import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  receipt: {
    marginTop: '16px',
    display: 'block',
    backgroundColor: '#eee',
    padding: '10px 3%',
    fontSize: '12px',
    lineHeight: '16px'
  },
  changeText: {
    // need to have important as otherwise MUI overrides it
    margin: '6px 6px 0px 0px !important'
  },
  receiptPlain: {
    marginTop: '10px',
    padding: '10px 3%',
    fontSize: '12px',
    lineHeight: '16px',
    border: '1px solid #ddd'
  },
  orderSummary: {
    marginBottom: '12px',
    marginTop: '4px',
    fontWeight: 700,
    fontSize: '18px',
    lineHeight: '24px',
    width: '50%',
    // if less than 375px, make width 100%
    '@media (max-width: 375px)': {
      width: '100%'
    }
  },
  subscriptionSummary: {
    fontWeight: 700,
    marginBottom: '22px',
    fontSize: '12px',
    lineHeight: '16px',
    width: '50%',
    // if less than 375px, make width 100%
    '@media (max-width: 375px)': {
      width: '100%',
      marginBottom: '8px'
    }
  },
  subscribeAndSaveChooserContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    width: '50%',
    marginBottom: '8px',
    // if less than 375px, make width 100%
    '@media (max-width: 375px)': {
      width: '100%',
      marginBottom: '8px'
    }
  },
  receiptHeader: {
    display: 'flex',
    width: '100%',
    '@media (max-width: 375px)': {
      display: 'block'
    }
  }
}));
