import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
  left: {
    textAlign: 'left',
    minWidth: '70%',
    display: 'inline-block'
  },
  right: {
    textAlign: 'right',
    minWidth: '30%',
    display: 'inline-block'
  },
  lineTotal: {
    borderTop: '1px solid #ddd',
    paddingTop: '10px',
    marginTop: '14px',
    fontWeight: 700
  }
}));
